// src/templates/blog-post.js

import * as React from "react";
import { Link, graphql } from "gatsby";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";

// import Bio from "../components/bio";
import Layout from "../components/layout";
import LinkCaptionSpecial from "../components/linkCaption";
import Seo from "../components/seo";

const slug = require(`../utils/slug`);

/*
 * *** blog-post template  ***
 * ---------------------------
 */

const mdxComponents = {
  h2: (props) => (
    <h2 className="pt-12 pb-4 xl3SerifSemibold">{props.children}</h2>
  ),
  h3: (props) => <h2 className="pt-6 pb-2 lgSerifBold">{props.children}</h2>,
  h4: (props) => <h3 className="pt-4 pb-2 font-bold">{props.children}</h3>,
  p: (props) => (
    <p className="pb-4 lg first:font-bold first:pb-16" {...props} />
  ),
  ul: (props) => (
    <ul className="px-12 pt-2 pb-6 lg list-interpunct" {...props} />
  ),
  li: (props) => <li className="pb-2" {...props} />,
  a: (props) => <a className="underline" {...props} />,
  blockquote: (props) => <blockquote className="blogBlockquote" {...props} />,
  LinkCaptionSpecial,
};

const BlogPostTemplate = ({ data, location }) => {
  const post = data.mdx;
  const { previous, next } = data;

  return (
    <Layout location={location}>
      <Seo
        lang={post.frontmatter.language}
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        className="l-typeArea"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline" className="xl6Light -ml-0.5">
            {post.frontmatter.title}
          </h1>
          <p className="pb-16">Published {post.frontmatter.date}</p>
        </header>

        <section>
          <MDXProvider components={mdxComponents}>
            <MDXRenderer>{post.body}</MDXRenderer>
          </MDXProvider>
        </section>
      </article>

      <nav className="my-20 l-typeArea">
        {/* TODO make component */}
        <div className="pb-2 border-b border-gray-700 border-dotted">
          More Articles
        </div>
        <ul className="flex w-full pt-6">
          <li className="w-1/2 pr-2 ">
            {previous && (
              <Link
                to={slug.getPathFromBlogFolderName(previous.fields.slug)}
                className="hover:no-underline lgSerif"
                rel="prev"
              >
                <div className="flex">
                  <div className="pr-2">←</div>
                  <div className="pr-6 truncate hover:underline ">
                    <span className="no-underline baseBold">Previous: </span>
                    {previous.frontmatter.title}
                  </div>
                </div>
              </Link>
            )}
          </li>
          <li className="w-1/2 pl-2 text-right">
            {next && (
              <Link
                to={slug.getPathFromBlogFolderName(next.fields.slug)}
                className="hover:no-underline lgSerif"
                rel="next"
              >
                <div className="flex">
                  <div className="pl-6 truncate hover:underline ">
                    <span className="no-underline baseBold">Next: </span>
                    {next.frontmatter.title}
                  </div>
                  <div className="pl-2">→</div>
                </div>
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        language
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
    previous: mdx(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: mdx(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
