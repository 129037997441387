// src/components/linkCaption.js

import * as React from "react";

/**
 * *** linkCaption ***
 * ---------------
 * Special for mdx, where a link as to be in the caption
 */

const LinkCaptionSpecial = ({ className, children }) => {
  return (
    <div
      className={`${className} mb-16 -mt-20 text-sm font-light`}
    >
      {children}
    </div>
  );
};

export default LinkCaptionSpecial;
